/* You can add global styles to this file, and also import other style files */
@import "primeng/resources/themes/lara-light-blue/theme.css";
@import "primeng/resources/primeng.css";

* {
    font-family: "Quicksand", sans-serif;
}

:root {
    --primaryColor: #1b548d;
    --primaryColorHover: #3b82f6;
    --primaryColorActive: #1D4ED8;
    --primaryColorOpacity: #1b548dd1;
    --borderLogin: white;
    --white: white;
    --redGoogle: #ce5643;
    --blueLinkedin: #007ab5;
    --blueOTLogin: #2C95FF;
    --blueSignIn: #162B40;
    --cancelColor: red;
    --cancelColorHover: rgb(120, 56, 56);
    --btnInactive: #D9D9D9;
    --btnDisabled: #a8abad;
    --backgroundInput: #f7f7f7;
    --buttonTextColor: #ffffff;
    --boxShadowActive: #9dc1fb;
    --backgroundSelection: #2196f329;
}


.content {
    width: 100%;
    //background-image: url("./assets/home_bg.png");
    background-size: cover;
}

.content_nobg {
    min-height: calc(100vh - 130px);
    //width: 100%;
    background-color: var(--white);
}

.input_form {
    width: 95%;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.centrado {
    text-align: center;
}

.al100 {
    width: 100%;
}

.myBtn {
    border-radius: 30px;
    color: var(--white);
    border: none;
    padding: 10px;
    width: 100%;
}

.btn_primary {
    background-color: var(--primaryColor);

    &:hover {
        background-color: var(--primaryColorHover);
    }
}

.btn_secondary {
    background-color: var(--cancelColor);

    &:hover {
        background-color: var(--cancelColorHover);
    }
}

.action-container {
    margin-left: 10px;
    display: flex;
}

.phone-action-icon {
    margin: 0px 5px;
}

.green {
    color: green;
}

.red {
    color: red;
}

.blue {
    color: blue;
}

.primary {
    color: var(--primaryColor);
}

.cancel {
    color: var(--cancelColor);
}


.but {
    padding: 5px;
    min-width: 60px;
    background-color: var(--btnInactive);
}

.btn_initial_pill {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.btn_middle_pill {}

.btn_last_pill {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.btn_inactive {
    background-color: var(--btnInactive);
    color: black;
}

.btn_active {
    background-color: var(--primaryColor);
    color: var(--white);
}

.filter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.separator {
    margin-top: 15px;
}

.btn_primary {
    background-color: var(--primaryColor);
    border-radius: 30px;
    color: var(--white);
    border: none;
    padding: 10px;
    width: 100%;
    margin-top: 15px;

    &:hover {
        background-color: var(--primaryColorHover);
    }

    &:disabled {
        background-color: var(--btnDisabled);
    }
}

::ng-deep .p-inputtext {
    background-color: var(--backgroundInput) !important;
}


::ng-deep .p-button {
    background: var(--primaryColor);
}

::ng-deep .p-button:enabled:hover {
    background: var(--primaryColorHover);
}


.customButton {
    margin: 0;
    display: inline-flex;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    align-items: center;
    vertical-align: bottom;
    text-align: center;
    overflow: hidden;
    position: relative;
    color: var(--buttonTextColor);
    background: var(--primaryColor);
    border: 1px solid var(--primaryColor);
    padding: 0.75rem 1.25rem;
    font-size: 1rem;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
    border-radius: 6px;
    text-transform: none;
    line-height: inherit;
    cursor: pointer;
    justify-content: center;

    &:hover {
        background: var(--primaryColorHover);
        color: var(--buttonTextColor);
        border-color: var(--primaryColorHover);
    }

    &:disabled {
        background: var(--btnDisabled);
    }

    &:enabled {
        &:active {
            background: var(--primaryColorActive);
            color: var(--buttonTextColor);
            border-color: var(--primaryColorActive);
        }
    }

    &:focus {
        box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--boxShadowActive), 0 1px 2px 0 black;
    }
}

.loader {
    border: 16px solid #f3f3f3;
    /* Light gray border */
    border-top: 16px solid #3498db;
    /* Blue border on top */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 5s linear infinite;
    /* Animation for rotation */
    margin: auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    /* Starting point */
    100% {
        transform: rotate(360deg);
    }

    /* Ending point */
}

.editBtn {
    padding-left: calc(var(--bs-gutter-x) * .5);
    color: var(--blueLinkedin);
    font-size: medium;
    cursor: pointer;

    &:hover {
        color: var(--primaryColorHover);
    }
}

.saveBtn {
    color: var(--primaryColor);
    font-size: medium;
    cursor: pointer;

    &:hover {
        color: var(--primaryColorHover);
    }

    &:disabled {
        cursor: no-drop;
    }
}

.cancelBtn {
    padding-right: calc(var(--bs-gutter-x) * .5);
    margin-left: 15px;
    color: var(--cancelColor);
    font-size: medium;
    cursor: pointer;

    &:hover {
        color: var(--cancelColorHover);
    }
}

.swal2-container {
    z-index: 1350 !important;
}

.highlight {
    background-color: yellow;
}